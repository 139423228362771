import styled, { css, keyframes } from 'styled-components';
import { media } from 'styles/utils';
import { ButtonProps } from './types';

export const buttonLoaderAnimation = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  } 40% {
    transform: scale(1.0);
  }
`;

export const StyledButtonLoader = styled.div<ButtonProps>`
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    width: 6px;
    height: 6px;
    margin-right: 3px;
    background-color: ${({ theme }) => theme.colors.primary};
    border-radius: 100%;
    animation: ${buttonLoaderAnimation} 1.4s infinite ease-in-out both;

    &:nth-child(1) {
      animation-delay: -0.32s;
    }

    &:nth-child(2) {
      animation-delay: -0.16s;
    }

    ${({ variant, theme }) => (variant === 'CTA' || variant === 'primary') && css`
      background-color: ${theme.colors.white};
    `}
  }
`;

export const ButtonIcon = styled.div`
  margin: 0 8px 0 0;
  display: flex;
  align-items: center;
`;

export const StyledButton = styled.button<ButtonProps>`
  width: auto;
  min-width: 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  height: 48px;
  border-radius: 48px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 14px;
  line-height: normal;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;
  border: 1px solid ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.primary};
  transition: all .2s ease-in;

  ${({ isLoading }) => isLoading && css`
    cursor: not-allowed;
  `}

  ${({ variant }) => variant === 'secondary' && css`
    border: none;
    background-color: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.gray.dark};
  `}

  ${({ variant }) => variant === 'CTA' && css`
    border: none;
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    padding: 17px 31px;
  `}

  ${({ variant }) => variant === 'warning' && css`
    border: 1px solid ${({ theme }) => theme.colors.darkRed};
    color: ${({ theme }) => theme.colors.darkRed};
    background-color: transparent;
  `}

  ${({ variant }) => variant === 'outlined' && css`
    border: 1px solid ${({ theme }) => theme.colors.primary};
    background-color: ${({ theme }) => theme.colors.gray.light};
    color: ${({ theme }) => theme.colors.primary};
    padding: 17px 31px;
  `}

  ${({ variant }) => variant === 'outlined-black' && css`
    border: 1px solid ${({ theme }) => theme.colors.black};
    background-color: transparent;
    color: ${({ theme }) => theme.colors.black};
    padding: 17px 31px;
  `}

  svg {
    max-width: 24px;
    max-height: 24px;
  }

  span {
    margin-top: 2px;
  }

  ${({ $iconOnly }) => $iconOnly && css`
    width: 48px;

    ${ButtonIcon} {
      margin: 0;
    }
  `}

  ${({ $iconOnlyOnMobile }) => $iconOnlyOnMobile && css`
    width: 48px;

    ${ButtonIcon} {
      margin: 0;
    }

    > span {
      display: none;
    }

    ${media.tablet`
      width: auto;

      > span {
        display: block;
      }
    `}
  `}

  ${({ $iconPosition }) => $iconPosition === 'right' && css<ButtonProps>`
    flex-direction: row-reverse;

    ${ButtonIcon} {
      margin: 0 0 0 8px;

      ${({ $iconOnlyOnMobile }) => $iconOnlyOnMobile && css`
        margin: 0;

        ${media.tablet`
          margin: 0 0 0 8px;
        `}
      `}
    }
  `}

  ${({ size }) => size === 'auto' && css`
    width: auto;
  `}

  ${({ size }) => size === 'fullWidth' && css`
    width: 100%;
  `}

  ${({ size }) => size === 'small' && css`
    width: auto;
    padding: 0px 16px;
    height: 32px;
    text-transform: initial;
  `}

  ${({ disabled }) => disabled && css`
    cursor: not-allowed;
    border-image: none;
    opacity: 0.4;

    ${media.desktop`
      transition: none;
    `}
  `};
`;
