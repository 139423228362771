import * as i from 'types';
import * as React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { graphql, useStaticQuery } from 'gatsby';

import { lockBodyScroll } from 'services';
import { useDevice } from 'services/hooks';
import { NavigationItemContainer } from 'common/layout';
import CloseIcon from 'vectors/Close.svg';
import { NavigationButton } from 'common/interaction';

import {
  NavigationContainer,
  Overlay,
  NavigationList,
  IconContainer,
  LanguageContainer,
  ExtendedLink,
} from './styled';
import {
  desktopVariant,
  mobileVariant,
  menuListVariant,
} from './animationVariants';

export const Navigation: React.FC = () => {
  const data = useStaticQuery<GatsbyTypes.MainNavigationitemsQuery>(graphql`
    query MainNavigationitems {
      allContentfulComponentNavigationList(
        filter: { navigationType: { eq: "mainNavigation" } }
      ) {
        edges {
          node {
            id
            list {
              ... on ContentfulComponentNavigationSubItemlist {
                id
                listTitle
                body {
                  ... on ContentfulComponentSingleNavigationItem {
                    id
                    title
                    slug
                    openInNewTab
                  }
                  ... on ContentfulPageGeneral {
                    id
                    title
                    slug
                  }
                }
              }
              ... on ContentfulComponentSingleNavigationItem {
                id
                title
                slug
                openInNewTab
              }
              ... on ContentfulPageGeneral {
                id
                slug
                title
              }
            }
            node_locale
          }
        }
      }
    }
  `);

  const [isNavigationOpen, setIsNavigationOpen] = React.useState(false);
  const { languages, language, changeLanguage } = useI18next();
  const overlayRef = React.createRef<HTMLDivElement>();
  const { isDesktop } = useDevice();
  const itemsList = data?.allContentfulComponentNavigationList.edges.filter(
    (edge) => edge.node.node_locale === language,
  );
  const menuItems = itemsList[0].node
    .list as unknown as i.NavigationItemCollection;

  const handleChangeLanguage =
    (language: string) => (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      changeLanguage(language);
    };

  React.useEffect(() => {
    lockBodyScroll(isNavigationOpen);

    return () => {
      lockBodyScroll(false);
    };
  }, [isNavigationOpen]);

  const onClose = () => setIsNavigationOpen(false);

  const animationVariant = isDesktop ? desktopVariant : mobileVariant;

  return (
    <>
      <NavigationButton openNavigation={() => setIsNavigationOpen(true)} />
      <NavigationContainer
        animate={isNavigationOpen ? 'visible' : 'hidden'}
        initial="hidden"
        variants={animationVariant}
        key="layoutNavigation"
      >
        <IconContainer
          animate={isNavigationOpen ? 'open' : 'close'}
          initial="open"
          variants={animationVariant}
          key="navigationIconContainer"
        >
          <CloseIcon onClick={onClose} />
        </IconContainer>
        <NavigationList key="navigationList" variants={menuListVariant}>
          {menuItems?.map((item) => (
            <NavigationItemContainer
              navigationItem={item}
              onClose={onClose}
              key={item?.id}
            />
          ))}
        </NavigationList>
        <LanguageContainer>
          {languages &&
            languages.map((lang) => (
              <ExtendedLink
                key={lang}
                to="#"
                $isActive={lang === language}
                onClick={handleChangeLanguage(lang)}
              >
                {lang}
              </ExtendedLink>
            ))}
        </LanguageContainer>
      </NavigationContainer>
      {isNavigationOpen && <Overlay ref={overlayRef} onClick={onClose} />}
    </>
  );
};
