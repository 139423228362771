import * as i from 'types';
import { createSelector } from 'reselect';

export const selectMeSeries = (state: i.ReduxState) => state.meSeries.data.series || [];
export const selectMeContracts = (state: i.ReduxState) => state.meSeries.data.contracts || [];

export const selectActiveContract = (state: i.ReduxState) => state.meSeries.data.contracts?.[0];

export const selectTotalRemainingSeries = createSelector(
  selectMeSeries,
  (series) => {
    const result = series.reduce((sum, serie) => {
      return sum = sum + serie.classesRemaining;
    }, 0);

    return result;
  },
);
