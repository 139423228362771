import styled from 'styled-components';
import { media } from 'styles/utils';

export const HeaderContainer = styled.div<HeaderContainerProps>`
  background: ${({ imageUrl }) => `url(${imageUrl}) no-repeat center center`};
  min-height: 247px;
  height: 100%;
  background-size: cover;
  position: relative;
  margin-bottom: ${({ marginBottom }) => `${marginBottom ?? 60}px`};

  ${media.desktop<HeaderContainerProps>`
    height: 400px;
  `}
`;

type HeaderContainerProps = {
  imageUrl: string;
  marginBottom?: number;
};

export const Container = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  flex-direction: column;
  padding: 36px 24px 24px;
  gap: 24px;
  height: 100%;
  width: 100%;

  ${media.desktop`
    max-width: 1400px;
    margin: 0 auto;
    padding: 30px 24px 56px;
  `}
`;

export const LogoWrapper = styled.div`
  align-self: center;
`;

export const HeadingSpan = styled.span`
  text-decoration: underline;
  text-underline-offset: 10px;
`;

export const ChildWrapper = styled.div`
  position: absolute;
  margin: 0 auto;
  left: 24px;
  right: 24px;
  bottom: -30px;

  ${media.desktop`
    margin-right: 0;
    right: 24px;
    max-width: 312px;
    bottom: -22px;
  `}
`;
