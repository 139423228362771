import * as i from 'types';
import * as React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { SwiperSlide } from 'swiper/react';

import { useSelector, useDevice } from 'services/hooks';
import { formatDay, formatWrittenDate, formatTime } from 'services';
import { userUpcomingClassesData } from 'selectors';
import { Text } from 'common/typography';

import InstructorIcon from 'vectors/instructorIcon.svg';
import LocationMarker from 'vectors/locationMarker.svg';
import DurationIcon from 'vectors/duration.svg';

import {
  CarouselContainer, ImageContentContainer, CarouselSlide, ExtendedSwiper, CarouselImage,
  CarouselContentContainer, HorizontalLine, CarouselContent, Row, IconWrapper, ExtendedText,
  ClassesWrapper, Progressbar, ProgressbarContainer,
} from './styled';

export const AccountNavigationUpcomingClasses: React.FC = () => {
  const { isMobile } = useDevice();
  const { navigate } = useI18next();
  const slides = useSelector(userUpcomingClassesData) || [];
  const [barNumber, setActiveBar] = React.useState(0);

  const swiperParams = {
    className: 'carousel',
    loopFillGroupWithBlank: false,
    grabCursor: true,
    breakpoints: {
      300: {
        slidesPerView: 1.25,
        spaceBetween: 16,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 12,
      },
    },
  };

  const getImageUrl = (item: i.Serie) => {
    const description = item?.classDescription || '';
    const imageUrl = description.substring(
      description.indexOf('[') + 1,
      description.lastIndexOf(']'),
    );

    return imageUrl;
  };

  return (
    <ClassesWrapper>
      {slides && slides.length !== 0 && (
        <>
          <ExtendedText variant={['gray', 'medium']}>
            Booked classes ({slides.length})
          </ExtendedText>
          <CarouselContainer>
            <ExtendedSwiper
              onActiveIndexChange={({ activeIndex }) => {
                setActiveBar(activeIndex);
              }}
              {...swiperParams}
            >
              <SwiperSlide key="placeholder" className="carousel__placeholder" />
              {slides.map((el, index) => (
                <SwiperSlide
                  key={index}
                  className="carousel__slide"
                >
                  <CarouselSlide onClick={() => navigate(`/class/${el.classId}`)}>
                    <CarouselImage imageUrl={getImageUrl(el)}>
                      <ImageContentContainer>
                        <Text $size={30} variant="white" $lineHeight="40">
                          {el.classType}
                        </Text>
                        <Text $size={16} variant="white" $lineHeight="24">
                          {formatTime(el?.classDate)}
                          &nbsp;&#8226; Spot {el.spotLabel}
                        </Text>
                      </ImageContentContainer>
                    </CarouselImage>
                    <CarouselContentContainer>
                      <Text $size={16} $lineHeight="24">
                        {formatDay(el?.classDate)} - {formatWrittenDate(el?.classDate)}
                      </Text>
                      <HorizontalLine />
                      <CarouselContent>
                        <Row>
                          <IconWrapper>
                            <LocationMarker />
                          </IconWrapper>
                          <Text $size={12}>
                            {el.siteName}
                          </Text>
                        </Row>
                        <Row>
                          <IconWrapper>
                            <InstructorIcon />
                          </IconWrapper>
                          <Text $size={12}>
                            {el.instructorName}
                          </Text>
                        </Row>
                        <Row>
                          <IconWrapper>
                            <DurationIcon />
                          </IconWrapper>
                          <Text $size={12}>
                            {el.classLength}
                          </Text>
                        </Row>
                      </CarouselContent>
                    </CarouselContentContainer>
                  </CarouselSlide>
                </SwiperSlide>
              ))}
              {slides.length > 1 && (
                <SwiperSlide key="placeholder" className="carousel__placeholder" />
              )}
            </ExtendedSwiper>
          </CarouselContainer>
          {isMobile && (
            <ProgressbarContainer>
              {slides?.map((el, index) => (
                <Progressbar
                  isActive={index === barNumber}
                  key={el.classDate}
                />
              ))}
            </ProgressbarContainer>
          )}
        </>
      )}
    </ClassesWrapper>
  );
};
