import * as i from 'types';
import * as React from 'react';
import { ThemeProvider } from 'styled-components';

import GlobalStyle from 'styles';
import theme from 'styles/theme';
import { Meta, Navigation } from 'common/layout';
import { useIndexRedirects } from 'services/hooks';

import { AccountNavigation } from '../AccountNavigation';
import { Footer } from '../Footer';
import { Container } from './styled';

export const Template: React.FC<i.MetaDataProps> = ({
  meta, children,
}) => {
  useIndexRedirects();

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Meta {...{ ...meta }} />
      <Navigation />
      <AccountNavigation />
      <Container>
        {children}
      </Container>
      <Footer />
    </ThemeProvider>
  );
};
