import * as React from 'react';

export const CheckMark = () => {
  return (
    <svg
      width={18}
      height={14}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.59 10.58L1.42 6.41 0 7.82l5.59 5.59 12-12L16.18 0 5.59 10.58z"
        fill="currentColor"
      />
    </svg>
  );
};
