import styled from 'styled-components';
import { motion } from 'framer-motion';

import { media } from 'styles/utils';

export const NavigationList = styled(motion.div)`
  display: flex;
  flex-direction: column;
  padding: 16px 0 0 16px;
  flex: 1;
  gap: 12px;
  margin-bottom: 24px;

  & > a {
    font-size: 16px;
    line-height: 29px;
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-weight: 200;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.gray.medium};
    position: relative;
    width: fit-content;
    letter-spacing: 5px;

    &::after {
      content: '';
      display: block;
      height: 2px;
      width: 0;
      transition: width .3s ease;
      background: ${({ theme }) => theme.colors.black};
      position: absolute;
      left: 0;
      bottom: 0;
    }

    &:hover:after {
      width: 100%;
    }
  }
`;

export const NavigationSubTitle = styled.a`
  font-size: 22px;
  line-height: 40px;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.primary};
  text-decoration: none;
  color: ${({ theme }) => theme.colors.black};
  position: relative;
  cursor: pointer;

  ${media.desktop`
    font-size: 30px;
  `}

  &::after {
    content: '';
    display: block;
    height: 2px;
    width: 0;
    transition: width .3s ease;
    background: ${({ theme }) => theme.colors.black};
    position: absolute;
    left: 0;
    bottom: 0;
  }

  &:hover:after {
    width: 100%;
  }
`;

export const NavigationItem = styled(motion.div)`
  & > a {
    font-size: 22px;
    line-height: 40px;
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-weight: 200;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.black};
    position: relative;
    letter-spacing: 5.8px;

    ${media.desktop`
      font-size: 32px;
    `}

    &::after {
      content: '';
      display: block;
      height: 2px;
      width: 0;
      transition: width .3s ease;
      background: ${({ theme }) => theme.colors.black};
      position: absolute;
      left: 0;
      bottom: 0;
    }

    &:hover:after {
      width: 100%;
    }
  }
`;

export const SublistContainer = styled.div<FooterItemsProps>`
  max-height: ${({ isOpen }) => isOpen ? '100%' : '0'};
  overflow: hidden;
  transition: max-height .3s ease;
`;

type FooterItemsProps = {
  isOpen: boolean;
};
