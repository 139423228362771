import * as React from 'react';
import { useTranslation, useI18next, Link } from 'gatsby-plugin-react-i18next';
import { graphql, useStaticQuery } from 'gatsby';

import { selectTotalRemainingSeries, selectActiveContract } from 'selectors';
import { userClassesAttendance } from 'selectors/me';
import { selectClassCounter } from 'selectors/rewards';
import { useAuthenticationActions, useSelector } from 'services/hooks';
import { Text } from 'common/typography';
import { AccountNavigationUpcomingClasses } from 'modules/AccountNavigationDetails';
import WeightbarSvg from 'vectors/weightbar.svg';
import QrCodeSvg from 'vectors/qrcode.svg';
import CalendarSvg from 'vectors/calendar.svg';

import {
  UserDetailsContainer,
  Row,
  NavigationItem,
  NavigationList,
  NavigationFooter,
  CreditsContainer,
  CreditsSvgWrapper,
  CreditsContentWrapper,
} from './styled';
import { menuLabelVariant, menuListVariant } from './animationVariants';

export const AccountNavigationOverview: React.FC<AccountNavigationOverviewProps> = ({
  name, setCurrentStep,
}) => {
  const { navigate, language, path } = useI18next();

  const data = useStaticQuery<GatsbyTypes.AccountNavigationitemsQuery>(graphql`
    query AccountNavigationitems {
      allContentfulComponentNavigationList(
        filter: {
          navigationType: { eq: "accountNavigation" }
        }
      ) {
        edges {
          node {
            id
            list {
              ... on ContentfulComponentSingleNavigationItem {
                id
                title
                slug
              }
              ... on ContentfulPageGeneral {
                id
                slug
                title
              }
            }
            node_locale
          }
        }
      }
    }
  `);

  const { t } = useTranslation();
  const { logout } = useAuthenticationActions();
  const remainingSeries = useSelector(selectTotalRemainingSeries);
  const activeContract = useSelector(selectActiveContract);
  const seriesName = activeContract?.seriesName || 'Regular';
  const menuItems = data?.allContentfulComponentNavigationList?.edges[0].node?.list || [];
  const seriesAmount = activeContract ? 'Unlimited' : remainingSeries;
  const [enrolled, setEnrolled] = React.useState(0);
  const enrolledTotal = useSelector(userClassesAttendance)?.enrolled || 0;
  const totalClassCounter = useSelector(selectClassCounter);
  const totalClassValue = totalClassCounter?.currentValue || 0;
  const navigationUrl = path === '/' ? `${language}/classes` : '/classes';

  // Using rewards endpoint to count total classes like the app
  // All rewards begin with 1 as default for new users
  // If value equals 1 use old endpoint
  React.useEffect(() => {
    if (totalClassValue === 1) {
      setEnrolled(enrolledTotal);
    }
    else {
      setEnrolled(totalClassValue);
    }
  }, [totalClassValue]);

  return (
    <>
      <UserDetailsContainer>
        <Row>
          <Text $size={20} $weight={700} $lineHeight="24">
            {t('hi')} {name},
          </Text>
          <Text $size={20} $lineHeight="24">
            {t('welcome back')}
          </Text>
        </Row>
        <Row>
          <CreditsContainer onClick={() => setCurrentStep(1)}>
            <CreditsSvgWrapper>
              <WeightbarSvg />
            </CreditsSvgWrapper>
            <CreditsContentWrapper>
              <Text $weight={700} $size={16}>
                {seriesAmount}
              </Text>
              <Text variant="gray" $weight={400} $size={12} $lineHeight="8">
                workout credits
              </Text>
            </CreditsContentWrapper>
          </CreditsContainer>
          <CreditsContainer onClick={() => setCurrentStep(2)}>
            <CreditsSvgWrapper>
              <QrCodeSvg />
            </CreditsSvgWrapper>
            <CreditsContentWrapper>
              <Text $weight={700} $size={16}>
                {seriesName}
              </Text>
              <Text variant="gray" $weight={400} $size={12} $lineHeight="8">
                member
              </Text>
            </CreditsContentWrapper>
          </CreditsContainer>
        </Row>
        <Row>
          <CreditsContainer onClick={() => navigate(navigationUrl)}>
            <CreditsSvgWrapper>
              <CalendarSvg />
            </CreditsSvgWrapper>
            <CreditsContentWrapper>
              <Text $weight={700} $size={16}>
                {enrolled}
              </Text>
              <Text variant="gray" $weight={400} $size={12} $lineHeight="8">
                bookings
              </Text>
            </CreditsContentWrapper>
          </CreditsContainer>
        </Row>
      </UserDetailsContainer>
      <AccountNavigationUpcomingClasses />
      <NavigationList variants={menuListVariant} key="AccountNavigationList">
        {menuItems.map((item) => (
          <NavigationItem variants={menuLabelVariant} key={item?.id}>
            <Link to={`/${item?.slug}` || ''}>{item?.title}</Link>
          </NavigationItem>
        ))}
      </NavigationList>
      <NavigationFooter>
        <NavigationItem key="logoutItem" onClick={logout}>{t('logout')}</NavigationItem>
      </NavigationFooter>
    </>
  );
};

type AccountNavigationOverviewProps = {
  name?: string;
  setCurrentStep: (number: number) => void;
};
