import styled from 'styled-components';

import { media } from 'styles/utils';

export const AccountSeriesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 32px 64px 32px;

  ${media.desktop`
    padding: 0 96px 96px 96px;
    height: 100%;
  `}
`;

export const SeriesAmountContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const SeriesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 25px;
`;

export const SeriesCard = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  display: grid;
  grid-template-columns: auto 20%;
  padding: 16px 24px 16px 24px;
`;

export const SeriesContentContainer = styled.div`
  grid-column: 1;
  display: flex;
  flex-direction: column;
  align-content: center;
  cursor: pointer;
`;

export const ButtonWrapper = styled.div`
  margin: auto 0 40px 0;
`;
