import * as i from 'types';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

import { media } from 'styles/utils';

//Headline1 differs from normal headings, it is used for all of the pageHeader components.
//it's usecase is to be the only animated title of the page.
export const Headline1 = styled(motion.h1)<HeadlineProps>`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.white};
  text-transform: uppercase;
  line-height: 48px;
  font-size: 26px;
  margin: ${({ margin }) => margin || '24px 0'};
  text-align: ${({ isCentered }) => isCentered ? 'center' : 'left'};
  letter-spacing: 14.86px;

  ${({ color }) => color === 'black' && css`
    color: ${({ theme }) => theme.colors.black};
  `}

  ${media.desktop`
    font-size: 54px;
    line-height: 64px;
  `}
`;

//headlines 2 & 3 are used for dynamic content blocks such as the textContent.
//For generic spaces, the heading component is used.
export const Headline2 = styled.h2`
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.black};
  font-weight: 400;
  line-height: 40px;
  size: 24px;
  text-transform: uppercase;
  letter-spacing: 6px;
  
  ${media.desktop`
    font-size: 32px;
  `}
`;

export const Headline3 = styled.h3`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 4px;
  font-style: bold;
  text-transform: uppercase;
`;

type HeadlineProps = {
  color?: i.ThemeColors;
  margin?: string;
  isCentered?: boolean;
};
