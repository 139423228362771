//WIP: THE REST OF THE TRANSLATIONS NEEDS TO BE ADDED. STILL WAITING ON INPUT FROM THE CLIENT ON THIS.
//FOR NOW, I ADDED THE TRANSLATIONS FUNCTIONALITY ON ONE LINE, SO I CAN PICK THIS UP FASTER WHEN THE CONTENT IS SUPPLIED.
import * as React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { discountCodeToClipboardText, referralToClipboardText } from 'services/getClipboardCopy';
import { Text } from 'common/typography';
import { Button } from 'common/interaction';
import { copyToClipboard } from 'services';
import { useAuthenticationUser, useSelector } from 'services/hooks';
import { api } from 'services/api';
import { selectActiveContract } from 'selectors';

import { CtaWrapper } from './styled';

export const FooterCTA:React.FC<FooterCTAProps> = ({ discountcode }) => {
  const [referralId, setReferralId] = React.useState('');
  const { t } = useTranslation();
  const { authenticated, determined } = useAuthenticationUser();
  const isActiveContract = useSelector(selectActiveContract);

  React.useEffect(() => {
    if (determined && authenticated) {
      api.get({ path: '/proxy/zingfit/account/referrals/referralid' })
        .then((res) => {
          setReferralId(res?.referralId);
        });
    }
  }, [determined]);

  const copyReferralToClipboard = () => {
    const text = referralToClipboardText(referralId);

    copyToClipboard(text)
      .then(() => {
        alert('The referral link is copied to your clipboard. Paste it anywhere to share it with your friends.');
      })
      .catch((err) => {
        alert('something went wrong, please try again later');
        console.error(err);
      });
  };

  const copyDiscountcodeToClipboard = () => {
    const text = discountCodeToClipboardText;

    copyToClipboard(text)
      .then(() => {
        alert('The discount code is copied to your clipboard. Paste it anywhere to share it with your friends!');
      })
      .catch((err) => {
        alert('something went wrong, please try again later');
        console.error(err);
      });
  };

  return (
    <>
      {!authenticated && (
        <CtaWrapper>
          <Text
            $uppercase
            $size={16}
            $margin="0 0 12px"
            $weight={700}
          >
            {t('Join our tribe!')}
          </Text>
          <Text
            $lineHeight="24"
            $size={14}
            $margin="0 0 24px"
            $weight={400}
          >
            {t('register quickly')}
          </Text>
          <Button variant="CTA" to="/register">{t('Register now')}</Button>
        </CtaWrapper>
      )}

      {authenticated && isActiveContract && (
        <CtaWrapper>
          <Text
            $uppercase $size={16}
            $margin="0 0 12px"
            $weight={700}
          >
            {t('Share discount')}
          </Text>
          <Text
            $lineHeight="24"
            $size={14}
            $margin="0 0 24px"
            $weight={400}
          >
            {t('Share your unique url')}
          </Text>
          <Button variant="CTA" onClick={() => copyReferralToClipboard()}>{t('Copy unique code')}</Button>
        </CtaWrapper>
      )}

      {authenticated && !isActiveContract && (
        <CtaWrapper>
          <Text
            $uppercase
            $size={16}
            $margin="0 0 12px"
            $weight={700}
          >
            {t('Share discount')}
          </Text>
          <Text
            $lineHeight="24"
            $size={14}
            $margin="0 0 24px"
            $weight={400}
          >
            {t('Share unique discount')}
          </Text>
          <Button onClick={() => copyDiscountcodeToClipboard()}variant="CTA">{t('Copy code')}</Button>
        </CtaWrapper>
      )}
    </>
  );
};

type FooterCTAProps = {
  discountcode: string;
};
