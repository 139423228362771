import styled, { css } from 'styled-components';

import { media, CONTAINER_WIDTH_DESKTOP } from 'styles/utils';

export const ContentContainer = styled.div<ContentContainerProps>`
  max-width: ${CONTAINER_WIDTH_DESKTOP}px;
  width: 100%;
  margin: 0 0 50px;
  padding: 0 24px;

  ${({ $textCenter }) => $textCenter && css`
    text-align: center;
  `}

  ${media.desktop<ContentContainerProps>`
    margin: 0 auto 120px;

    ${({ hasSmallMargin }) => hasSmallMargin && css`
      margin: 0 auto 60px;
    `}
  `}

  ${media.large`
    padding: 0;
  `}
`;

type ContentContainerProps = {
  $textCenter?: boolean;
  hasSmallMargin?: boolean;
};
