import * as i from 'types';
import * as React from 'react';
import { Link } from 'gatsby-plugin-react-i18next';

import { checkIfHome } from 'services';
import { ExternalLink } from 'common/interaction';

import { NavigationItem, NavigationSubTitle, SublistContainer, NavigationList } from './styled';

export const NavigationItemContainer:React.FC<NavigationItemContainerProps> = ({
  navigationItem, onClose,
}) => {
  const [openSubList, setOpenSubList] = React.useState(false);
  const hasBody = 'body' in navigationItem;

  const menuLabelAnimation = {
    visible: {
      opacity: 1,
      x: '0px',
    },
    hidden: {
      opacity: 0,
      x: '20px',
    },
  };

  const toggleMenu = () => setOpenSubList(!openSubList);

  return (
    <>
      {!hasBody && (
        <NavigationItem variants={menuLabelAnimation} key={navigationItem?.id}>
          {navigationItem.openInNewTab ? (
            <ExternalLink href={navigationItem.slug}>
              {navigationItem.title}
            </ExternalLink>
          ) : (
            <Link to={`/${checkIfHome(navigationItem.slug)}`} onClick={onClose}>
              {navigationItem?.title}
            </Link>
          )}
        </NavigationItem>
      )}

      {hasBody && (
        <NavigationItem variants={menuLabelAnimation} key={navigationItem?.id}>
          <NavigationSubTitle onClick={toggleMenu}>{navigationItem.listTitle}</NavigationSubTitle>
          <SublistContainer isOpen={openSubList}>
            <NavigationList variants={menuLabelAnimation} key={navigationItem?.id}>
              {navigationItem.body?.map((el) => (
                <React.Fragment key={el.id}>
                  {el.openInNewTab ? (
                    <ExternalLink href={el.slug}>
                      {el.title}
                    </ExternalLink>
                  ) : (
                    <Link key={el.id} to={`/${el.slug}` || ''}>
                      {el?.title}
                    </Link>
                  )}
                </React.Fragment>
              ))}
            </NavigationList>
          </SublistContainer>
        </NavigationItem>
      )}
    </>
  );
};

type NavigationItemContainerProps = {
  navigationItem: i.NavigationItemSingleSlug | i.NavigationItemWithBody;
  onClose: () => void;
};
