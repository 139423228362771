import styled from 'styled-components';
import { motion } from 'framer-motion';
import { media } from 'styles/utils';

import Close from 'vectors/Close.svg';
import { Heading } from 'common/typography';

export const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-content: center;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  height: 62.5vh;

  ${media.desktop`
    height: auto;
    min-width: 552px;
  `}
`;

export const ExtendedHeading = styled(Heading)`
  color: ${({ theme }) => theme.colors.black};
  line-height: 30px;

  ${media.desktop`
    line-height: 40px;
  `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  flex-direction: column;
  margin-top: auto;

  ${media.desktop`
    margin-top: 75px;
    flex-direction: row;
  `}
`;

export const ModalHeader = styled.div`
  display: flex;
  padding: 32px 24px 8px 24px;
  justify-content: flex-end;

  ${media.desktop`
    padding: 44px 44px 0 0;
  `}
`;

export const CloseIcon = styled(Close)`
  cursor: pointer;
`;

export const ContentContainer = styled.div`
  text-align: left;
  padding: 0 24px 32px 24px;
  display: flex;
  flex-direction: column;
  height: 100%;

  ${media.desktop`
    padding: 0 96px 45px 96px;
  `}
`;
