import styled from 'styled-components';
import { media } from 'styles/utils';

export const CloseButton = styled.button`
  background: ${({ theme }) => theme.colors.white};
  width: 48px;
  height: 48px;
  border-radius: 100%;
  border: none;
  position: absolute;
  right: 6%;
  top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
`;

export const VideoContainer = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  position: relative;

  ${media.desktop`
    height: unset;
  `}
`;

export const Video = styled.video`
  max-width: 90%;
  max-heigth: 80%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
`;
