import styled, { css } from 'styled-components';

import { media } from 'styles/utils';

export const Container = styled.div<ContainerProps>`
  display: grid;
  max-width: 100vw;
  height: 100vh;
  grid-template-columns: 100%;

  ${({ $backgroundColor }) => $backgroundColor && css`
    background-color: ${({ theme }) => theme.colors.gray.light};
  `}

  ${media.desktop`
    grid-template-columns: 50% 50%;
  `}
`;

export const Content = styled.div<ContentProps>`
  position: relative;
  grid-column-start: ${({ $first }) => $first ? '1' : '2'};

  ${({ $backgroundColor }) => $backgroundColor && css`
    background-color: ${({ theme }) => theme.colors.gray.light};
  `}

  ${({ $fixed, $first }) => $fixed && css`
    position: fixed;
    top: 0;
    left: ${$first ? '0' : '50%'};
    right: ${!$first ? '0' : '50%'};
    bottom: 0;
  `}

  ${({ $fixedOnDesktop, $first }) => $fixedOnDesktop && css`
    ${media.desktop`
      position: fixed;
      top: 0;
      left: ${$first ? '0' : '50%'};
      right: ${!$first ? '0' : '50%'};
      bottom: 0;
    `}
  `}

  ${({ $firstOnMobile }) => $firstOnMobile && css`
    order: -1;
  `}

  ${({ $hideOnMobile }) => $hideOnMobile && css`
    display: none;

    ${media.desktop`
      display: block;
    `}
  `}

  ${({ $hideOnMobile, $first }) => !$hideOnMobile && css`
    grid-column-start: span 2;

    ${media.desktop`
      grid-column-start: ${$first ? '1' : '2'};
    `}
  `}
`;

type ContentProps = {
  $first?: boolean;
  $fixed?: boolean;
  $hideOnMobile?: boolean;
  $fixedOnDesktop?: boolean;
  $backgroundColor?: boolean;
  $firstOnMobile?: boolean;
};

type ContainerProps = {
  $backgroundColor?: boolean;
}
