import * as React from 'react';

import { useSelector } from 'services/hooks';
import { formatDateShort } from 'services';
import { selectActiveContract } from 'selectors';
import { Text } from 'common/typography';
import CardBackgroundImage from 'images/login.jpg';

import {
  MembershipCardWrapper, MembershipCardImage, StyledLogo, MembershipCardContent, Row,
  HorizontalLine, Container, Subtitle, NavigationContainer,
} from './styled';

export const AccountNavigationMembercard: React.FC<MembercardProps> = ({ name, emailaddress, dateCreated }) => {
  const activeContract = useSelector(selectActiveContract);
  const seriesName = activeContract?.seriesName || 'Regular';

  return (
    <NavigationContainer>
      <Text
        $size={20}
        $weight={700}
        $lineHeight="24px"
      >
        Membership
      </Text>
      <MembershipCardWrapper>
        <MembershipCardImage background={CardBackgroundImage}>
          <StyledLogo />
        </MembershipCardImage>
        <MembershipCardContent>
          <Text
            $size={20}
            $weight={400}
            $lineHeight="24px"
          >
            {name}
          </Text>
          <HorizontalLine />
          <Container>
            <Subtitle variant="gray">email address</Subtitle>
            <Text
              $weight={400}
              $size={14}
              $lineHeight="20"
            >
              {emailaddress}
            </Text>
          </Container>
          <HorizontalLine />
          <Row>
            <Container>
              <Subtitle variant="gray">membership</Subtitle>
              <Text
                $weight={700}
                $size={18}
                $lineHeight="20"
              >
                {seriesName}
              </Text>
            </Container>
            <Container>
              <Subtitle variant="gray">since</Subtitle>
              <Text
                $weight={700}
                $size={18}
                $lineHeight="20"
              >
                {formatDateShort(dateCreated)}
              </Text>
            </Container>
          </Row>
        </MembershipCardContent>
      </MembershipCardWrapper>
    </NavigationContainer>
  );
};

type MembercardProps = {
  name: string;
  emailaddress: string;
  dateCreated?: string;
};
