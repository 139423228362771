import styled, { css } from 'styled-components';
import { Swiper } from 'swiper/react';

import { media, CONTAINER_WIDTH_DESKTOP } from 'styles/utils';

export const ExtendedSwiper = styled(Swiper)<IsSmallProp>`
  ${({ isSmall }) => isSmall && css`
    display: flex;
    flex-direction: column-reverse;
  `}
`;

export const CarouselContainer = styled.div`
  margin-bottom: 60px;

  .carousel {
    width: 100%;
  }

  .carousel__slide {
    width: 500px;
    cursor: pointer;
  }

  .carousel__placeholder {
    display: none;

    ${media.desktop`
      display: block;
      width: calc((100vw - ${CONTAINER_WIDTH_DESKTOP}px) / 2);
    `}
  }

  .swiper-button-disabled {
    display: block;
    opacity: .3;
    cursor: default;
  }

  ${media.desktop`
    margin-bottom: 120px;
  `}
`;

export const CarouselSlide = styled.div<CarouselSlideProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-decoration: none;

  ${({ isSmall }) => !isSmall && css`
    height: 225px;

    ${media.tablet`
      height: 500px;
    `}
  `}

  ${({ isClickable }) => !isClickable && css`
    cursor: default;
  `}

  ${media.tablet`
    width: 500px;
  `}
`;

type CarouselSlideProps = IsSmallProp & {
  isClickable: boolean;
};

export const SwiperContainer = styled.div`
  width: 100%;
  position: relative;
  margin-left: 500px;
`;

export const CarouselImage = styled.div<CarouselImageProps>`
  height: 320px;
  width: 100%;
  background: ${({ imageUrl }) => `url(${imageUrl})`};
  background-size: cover;
  background-position: center;

  ${({ isSmall }) => !isSmall && css`
    position: absolute;
    height: 100%;
    z-index: -1;
  `}
`;

type CarouselImageProps = IsSmallProp & {
  imageUrl: string;
};

export const CarouselContent = styled.div<IsSmallProp>`
  padding: 8px;

  p {
    display: ${({ isSmall }) => isSmall ? 'block' : 'none'};
    font-size: 12px;
  }

  h3 {
    text-align: ${({ isSmall }) => isSmall ? 'left' : 'center'};
    font-size: 11px;
  }

  ${media.tablet<IsSmallProp>`
    padding: ${({ isSmall }) => isSmall ? '16px 0 8px' : '16px 40px 40px'};

    p {
      display: block;
      font-size: 14px;
    }

    h3 {
      text-align: left;
      font-size: 20px;
    }
  `}
`;

type IsSmallProp = {
  isSmall?: boolean;
};

export const NavigationContainer = styled.div`
  margin-left: calc((100vw - ${CONTAINER_WIDTH_DESKTOP}px) / 2);
`;
