import * as i from 'types';
import styled, { css } from 'styled-components';

import { getColor, media } from 'styles/utils';

const headingSizes = {
  h1: {
    mobile: '28px',
    desktop: '64px',
  },
  h2: {
    mobile: '22px',
    desktop: '30px',
  },
  h3: {
    mobile: '16px',
    desktop: '20px',
  },
  h4: {
    mobile: '12px',
    desktop: '14px',
  },
};

const lineHeightSizes = {
  h1: '64px',
  h2: '30px',
  h3: '30px',
  h4: '15px',
};

export const Heading = styled.h1<HeadingProps>`
  line-height: ${({ as }) => lineHeightSizes[as || 'h1']};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ as }) => headingSizes[as || 'h1'].mobile};
  color: ${({ theme }) => theme.colors.white};
  margin: ${({ margin }) => margin || '24px 0'};
  text-transform: ${({ upperCase }) => upperCase ? 'uppercase' : 'none'};
  text-align: ${({ isCentered }) => isCentered ? 'center' : 'left'};
  letter-spacing: ${({ as, letterSpacing }) => letterSpacing || as === 'h3' ? '6px' : 'none'};
  font-weight: ${({ weight }) => weight || 400};

  ${({ variant }) => variant && css`
    color: ${getColor(variant)};
  `}

  ${media.desktop<HeadingProps>`
    font-size: ${(props) => headingSizes[props.as || 'h1'].desktop};
  `}
`;

type HeadingProps = {
  as?: 'h1' | 'h2' | 'h3' | 'h4';
  margin?: string;
  isCentered?: boolean;
  upperCase?: boolean;
  variant?: i.TextColors;
  weight?: number;
  letterSpacing?: string;
};
