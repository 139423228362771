import * as i from 'types';
import { createSelector } from 'reselect';
import dayjs from 'dayjs';

import { isSameOrAfterToday, isBeforeToday } from 'services';

export const meDataSelector = (state: i.ReduxState) => state.me.data;
export const editMeLoading = (state: i.ReduxState) => state.me.loading.editMe;

export const userClassesData = (state: i.ReduxState) => state?.me?.classes?.content;
export const userClassesAttendance = (state: i.ReduxState) => state?.me?.classes?.attendance;

export const userUpcomingClassesData = createSelector(
  userClassesData,
  (items) => {
    if (items) {
      return items
        .filter((item) => isSameOrAfterToday(item.classDate) && item.status !== 'Cancelled' && item.status !== 'No Show')
        .sort((a, b) => new Date(a.classDate).valueOf() - new Date(b.classDate).valueOf());
    }
  },
);

export const userUpcomingOverviewClassesData = createSelector(
  userClassesData,
  (items) => {
    if (items) {
      return items
        .filter((item) => isSameOrAfterToday(item.classDate))
        .sort((a, b) => new Date(b.classDate).valueOf() - new Date(a.classDate).valueOf());
    }
  },
);

export const userPastClassesData = createSelector(
  userClassesData,
  (items) => {
    if (items) {
      return items
        .sort((a, b) => new Date(b.classDate).valueOf() - new Date(a.classDate).valueOf())
        .filter((item) => item.status !== 'Waitlist Expired')
        .reduce((acc, cur) => {
          if (isBeforeToday(cur.classDate)) {
            const indexOfArrayMatch = acc.findIndex((item) => (
              dayjs(item.date).format('MMMM YYYY') === dayjs(cur.classDate).format('MMMM YYYY')
            ));

            if (indexOfArrayMatch >= 0) {
              acc[indexOfArrayMatch].classes.push(cur);
            } else {
              acc.push({
                date: cur.classDate,
                classes: [cur],
              });
            }
          }

          return acc;
        }, [] as PastClassesArray[]);
    }
  },
);

export const userEnrolledClassesVisible = createSelector(
  userClassesData,
  (items) => {
    if (items) {
      return items
        .filter((item) => isBeforeToday(item.classDate) && item.status === 'Enrolled')
        .length;
    }
  },
);

type PastClassesArray = {
  date: string;
  classes: i.Serie[];
};
