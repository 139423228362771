import * as React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { useSelector } from 'services/hooks';
import { formatWrittenDateYear } from 'services/formatDate';
import { selectTotalRemainingSeries, selectMeSeries } from 'selectors';
import { Button } from 'common/interaction';
import { Text } from 'common/typography';
import PlusIcon from 'vectors/plus.svg';

import {
  SeriesList,
  SeriesCard,
  SeriesContentContainer,
  SeriesAmountContainer,
  AccountSeriesWrapper,
  ButtonWrapper,
} from './styled';

export const AccountNavigationSeries: React.FC = () =>  {
  const totalAmountSeries = useSelector(selectTotalRemainingSeries);
  const series = useSelector(selectMeSeries);
  const generateName = (name: string) => name.split('[')[0];
  const { t } = useTranslation();

  return (
    <AccountSeriesWrapper>
      <Text
        $size={20}
        $weight={700}
        $lineHeight="24px"
      >
        {totalAmountSeries} class credits
      </Text>
      <SeriesList>
        {series && series?.map((serie) => (
          <SeriesCard key={serie.id}>
            <SeriesContentContainer>
              <Text
                $size={16}
                $uppercase $weight={700}
                $lineHeight="24"
              >
                {generateName(serie.seriesName)}
              </Text>
              <Text
                variant="gray"
                $lineHeight="24"
                $size={14}
              >
                {serie.unlimited
                  ? 'Unlimited workouts'
                  : `Valid until ${formatWrittenDateYear(serie.expiringDate)}`
                }
              </Text>
            </SeriesContentContainer>
            {!serie.unlimited && (
              <SeriesAmountContainer>
                <Text
                  variant="primary"
                  $size={20}
                  $weight={700}
                  $lineHeight="24"
                >
                  {serie.classesRemaining}/{serie.classCount}
                </Text>
              </SeriesAmountContainer>
            )}
          </SeriesCard>
        ))}
      </SeriesList>
      <ButtonWrapper>
        <Button
          size="fullWidth"
          to="/pricing"
          icon={<PlusIcon />}
        >
          {t('buy classes')}
        </Button>
      </ButtonWrapper>
    </AccountSeriesWrapper>
  );
};
