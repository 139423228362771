import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

import { media } from 'styles/utils';

export const AccountButton = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  height: 48px;
  padding: 0 12px;
  border-radius: 24px;
  cursor: pointer;
  box-shadow: 2px 10px 112px -1px rgba(0,0,0,0.41);
  position: fixed;
  right: 24px;
  top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  color: ${({ theme }) => theme.colors.primary};

  p {
    display: none;
  }

  svg {
    width: 24px;
    height: 24px;
    fill: ${({ theme }) => theme.colors.black};
  }

  ${media.desktop`
    top: 32px;
    right: 32px;

    p {
      display: block;
    }
  `}
`;

export const AccountButtonText = styled.p<AccountButtonTextProps>`
  color: ${({ theme }) => theme.colors.black};
  font-size: 14px;
  font-weight: bold;
  padding: 0 10px;

  ${({ loginText }) => loginText && css`
    font-weight: 400;
    border-right: 1px solid ${({ theme }) => theme.colors.black};
  `};
`;

type AccountButtonTextProps = {
  loginText?: boolean;
};

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.3);
`;

export const NavigationContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray.light};
  z-index: 10;
  position: relative;
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  ${media.desktop`
    width: 600px;
  `}
`;

export const IconContainer = styled(motion.div)`
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  margin-bottom: 28px;
`;

export const NavigationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding: 48px 32px 0px 32px;

  ${media.desktop`
    padding: 48px 96px 0px 96px;
  `}
`;
