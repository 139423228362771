import * as i from 'types';
import * as React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { Navigation } from 'swiper';
import { SwiperSlide } from 'swiper/react';

import { Heading, Text } from 'common/typography';
import { CarouselNavigation } from 'common/interaction';
import { configureImageSrc } from 'services';

import {
  CarouselContainer, CarouselSlide, CarouselImage, CarouselContent, ExtendedSwiper, NavigationContainer,
} from './styled';

export const Carousel: React.FC<CarouselProps> = ({ slides, isSmall }) => {
  const { navigate } = useI18next();

  // Needed to fix libraries ts error
  const auto = 'auto';
  const swiperParams = {
    className: 'carousel',
    loopFillGroupWithBlank: false,
    grabCursor: true,
    navigation: {
      navigation: true,
      prevEl: '.carousel__prev',
      nextEl: '.carousel__next',
    },
    breakpoints: {
      300: {
        slidesPerView: isSmall ? 1 : 1.8,
        spaceBetween: 8,
      },
      768: {
        slidesPerView: auto as 'auto',
        spaceBetween: 0,
      },
    },
  };

  const navigateTo = (url?: string) => {
    if (url) navigate(url);
  };

  return (
    <CarouselContainer>
      <ExtendedSwiper
        modules={[Navigation]}
        {...swiperParams}
        isSmall={isSmall}
      >
        <SwiperSlide key="placeholder" className="carousel__placeholder" />
        {/* when adding the same items to the page, id's are identical. That is why index is used here */}
        {slides?.map((el, index) => (
          <SwiperSlide
            key={index}
            className="carousel__slide"
          >
            <CarouselSlide
              isSmall={isSmall}
              isClickable={!!el.cta}
              onClick={() => navigateTo(el.cta)}
            >
              <CarouselImage imageUrl={configureImageSrc(el.frontImage?.file?.url)} isSmall={isSmall} />
              <CarouselContent isSmall={isSmall}>
                <Heading
                  as="h3"
                  margin="0 0 4px"
                  weight={700}
                  variant={isSmall ? 'black' : 'white'}
                  upperCase
                >
                  {el.title}
                </Heading>
                <Text $size={16} $lineHeight="24" variant={isSmall ? 'black' : 'white'}>
                  {el.tagline}
                </Text>
              </CarouselContent>
            </CarouselSlide>
          </SwiperSlide>
        ))}
        <NavigationContainer>
          <CarouselNavigation />
        </NavigationContainer>
      </ExtendedSwiper>
    </CarouselContainer>
  );
};

type CarouselProps = {
  isSmall?: boolean;
  slides: {
    title: string;
    tagline?: string;
    frontImage: i.MediaType;
    cta?: string;
    slug?: string
  }[];
};
